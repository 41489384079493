
  select {
    /* Reset Select */
    appearance: none;
    outline: 0;
    border: 0;
    box-shadow: none;
    /* Personalize */
    flex: 1;
    padding: 0 1em;
    background-color: var(--darkgray);
    background-image: none;
    cursor: pointer;
    color: black;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  /* Custom Select wrapper */
  .select {
    position: relative;
    display: flex;
    width: 300px;
    height: 44px;
    border-radius: .25em;
    overflow: hidden;
    border: 1px solid #233B25;
    color: black !important;
  }
  /* Arrow */
  .select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    transition: .25s all ease;
    pointer-events: none;
  }

.custom-dropdown{
	margin-top: 30px;
}

  /*Dropdown....*/
  input[type="checkbox"], .dropdown-content, input[type="radio"] {
	 display: none;
}
 .dropdown-wrapper.active .dropdown-content {
	 display: block;
}
 input[type="radio"] + label {
	 cursor: pointer;
	 padding-left: 35px;
	 position: relative;
}
 input[type="radio"] + label:before {
	 left: 0;
	 position: absolute;
	 content: "";
	 width: 20px;
	 height: 20px;
	 border-radius: 50%;
	border: 4px solid #C4C4C4;
	 margin-top: 3px;
}

 input[type="radio"]:checked + label:before {
	 left: -3px;
	 background: #666666;
	 border: 4px solid #C4C4C4;
	 -webkit-box-shadow: 0px 0px 0px 2px #C4C4C4;
	 box-shadow: 0px 0px 0px 2px #C4C4C4;
	 margin-top: 3px;
}

 .dropdown-wrapper {
	 width: 300px;
	 height: 44px;
}
.dropdown-button{
	background-color: transparent;
	border: none;
	width: 100%;
	border: 1px solid #233B25;
    padding: 10px 11px;
    border-radius: 4px;
}
.toggle{
	float: right;
}
 .dropdown-content {
	 border-radius: 5px;
	 position: absolute;
	 padding: 15px;
	 left: 50%;
	 top: 40px;
	 -webkit-transform: translate(-50%, 0);
	 transform: translate(-50%, 0);
	 width: 295px;
	 box-shadow: 0px 2px 18px rgba(35, 59, 37, 0.08);
	 background-color: white;
	 color: black;
}
 .dropdown-content ul, .dropdown-content li {
	 margin: 0;
	 padding: 0;
	 color: black !important;
}
 .dropdown-content ul {
	 list-style: none;
}

 .dropdown-content:after {
	 border-color: rgba(0, 0, 0, 0);
	 border-bottom-color: #fff f;
	 border-width: 10px;
	 margin-left: -10px;
}
 .dropdown-content:before {
	 border-color: rgba(179, 179, 191, 0);
	 border-bottom-color: #b3b3bf;
	 border-width: 11px;
	 margin-left: -11px;
}
 .icon-wrapper {
	 color: #b3b3bf;
	 width: 100%;
}
 .icon-wrapper i {
	 position: relative;
	 margin-left: 5px;
	 width: 20px;
	 height: 20px;
	 font-size: 22px !important;
	 border-radius: 50%;
	 text-align: center;
	 display: block;
	 cursor: pointer;
	 color: #2f255b;
}
.dropdown-button:focus{
	outline: none;
}
 .icon-wrapper i:before {
	 display: block;
	 line-height: 18px;
	 font-size: 10px;
}
 .icon-wrapper li {
	 padding-bottom: 15px;
	 max-height: 32px;
	 margin-top: 15px;
	 color: #2f255b;
}
 .icon-wrapper li:last-child {
	 border-bottom: 0;
	 padding-bottom: 0;
}
 .icon-wrapper li:first-child {
	 margin-top: 0;
}
 .icon-wrapper li span {
	 display: block;
	 padding-left: 35px;
	 font-size: 0.9em;
	 color: #b3b3bf;
}
 .icon-wrapper {
	 display: inline-block;
	 position: relative;
}
 .dropdown-heading {
	 color: #b3b3bf;
}

@media only screen and (max-width: 425px) {
	input[type="radio"] + label:before {
		left: 0;
		position: absolute;
		content: "";
		width: 20px;
		height: 20px;
		border-radius: 50%;
	   border: 4px solid #DCF9DE;
		margin-top: 3px;
   }

	input[type="radio"]:checked + label:before {
		left: -3px;
		background: #D63158;
		border: 4px solid #DCF9DE;
		-webkit-box-shadow: 0px 0px 0px 2px #DCF9DE;
		box-shadow: 0px 0px 0px 2px #DCF9DE;
		margin-top: 3px;
   }
}
